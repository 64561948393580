<template lang="pug">
  .notifications-table
    VueGoodTable(
      :rows="notifications"
      class="table-wrapper"
      :columns="columns"
      :sort-options="{ enabled: true, initialSortBy: sortBy }"
      @on-sort-change="$emit('sort-change', $event)"
      @on-row-click="onRowClick"
    )
      template(v-slot:table-column="props")
        span {{ $t(props.column.label) }}
        .checkbox(v-if="props.column.field === 'checkbox'")
          AppCheckbox.highlight-checked(
            :value="isAllSelected"
            :indeterminate="isSomeSelected"
            @change="toggleAll"
          )
      template(v-slot:table-row="{ column, row, formattedRow }")
        .checkbox(v-if="column.field === 'checkbox'")
          AppCheckbox.highlight-checked(
            :value="selectedNotificationsIds.includes(row.id)"
            @change="toggle(row.id)"
          )
        .created_at(v-if="column.field === 'failed_at'")
          | {{ row.created_at }}
        .ota(v-if="column.field === 'ota_name'")
          | {{ translateOtaName(row.ota_name) }}
        .object(v-if="column.field === 'object'")
          | {{ $t(`notifications.table.object.${row.error_number}`) }}
        .details(v-if="column.field === 'detail'")
          | {{ extractTranslatedAttribute(row) }}
        .status(
          v-if="column.field === 'read'"
          :class="{ selected: selectedNotificationsIds.includes(row.id) }"
        )
          AppIcon(
            v-if="row.read"
            name="openedLetter"
          )
          AppIcon(
            v-else
            name="unreadLetter"
          )
      template(v-slot:emptystate)
        .text-center {{ noDataMessage }}
</template>

<script>
  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { map } from "lodash-es"
  import "vue-good-table/dist/vue-good-table.css"
  import { columns } from "./NotificationsTableConfig"
  import { extractTranslatedAttribute, translateOtaName } from "@/helpers/common"

  export default {
    props: {
      notifications: {
        type: Array,
        default: () => new Array()
      },
      selectedNotificationsIds: {
        type: Array,
        default: () => new Array()
      },
      sortBy: {
        type: Object,
        default: () => {}
      },
      filters: {
        type: Object,
        required: true
      }
    },

    components: {
      VueGoodTable,
      AppIcon: () => import("@/components/elements/AppIcon"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    data() {
      return {
        columns
      }
    },

    computed: {
      isAllSelected({ selectedNotificationsIds, notifications }) {
        return selectedNotificationsIds.length === notifications.length
      },

      isSomeSelected({ selectedNotificationsIds }) {
        return selectedNotificationsIds.length > 0 && selectedNotificationsIds.length < this.notifications.length
      },

      noDataMessage({ filters: { read_status } }) {
        if (read_status.length == 1 && read_status[0].value === "unread") {
          return this.$t("notifications.table.no_data")
        }

        return this.$t("no_data")
      }
    },

    methods: {
      extractTranslatedAttribute,
      translateOtaName,

      changePagination(key, value) {
        this.$emit("change-pagination", { key, value })
      },

      onRowClick({ row: { id } }) {
        this.toggle(id)
      },

      toggle(id) {
        const selectedNotifications = this.selectedNotificationsIds
        const index = selectedNotifications.indexOf(id)

        if (index === -1) {
          selectedNotifications.push(id)
        } else {
          selectedNotifications.splice(index, 1)
        }

        this.$emit("select-notifications", selectedNotifications)
      },

      toggleAll() {
        const notificationsIds = map(this.notifications, "id")

        if (this.isAllSelected) {
          this.$emit("select-notifications", [])
        } else {
          this.$emit("select-notifications", notificationsIds)
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .checkbox
    ::v-deep
      .app-checkbox
        justify-content: center
        display: flex
        padding: 0

        .app-checkbox-icon
          margin: 0 !important

  .sort-icon
    cursor: pointer
    margin-left: 10px

  .notifications-table
    +listing-container(40px)

  ::v-deep
    .notifications-table
      &-th
        border-top: none
        letter-spacing: 0
        font-size: 0.8rem
        padding: 9px

      &-td
        color: $default-black
        font-size: 0.8rem
        letter-spacing: 0
        padding: 9px
        vertical-align: middle

        .selected
          svg
            use
              fill: $default-gray !important
            ellipse
              fill: $default-gray !important

    .failed_at
      width: 140px !important

    .ota_name
      width: 80px !important

    .object
      width: 150px !important

    .read
      width: 90px !important
</style>
