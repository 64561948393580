import { configPrepare } from "@/helpers/vue-good-table"

const i18nPrefix = "notifications.table.columns"
const defaults = {
  tdClass: "notifications-table-td",
  thClass: "notifications-table-th",
  sortable: false
}

const fields = [
  {
    label: "",
    field: "checkbox"
  },
  {
    field: "failed_at",
    thClass: "notifications-table-th failed_at"
  },
  {
    field: "ota_name",
    thClass: "notifications-table-th ota_name"
  },
  {
    field: "object",
    thClass: "notifications-table-th object"
  },
  "detail",
  {
    field: "read",
    type: "boolean",
    sortable: true,
    tdClass: "notifications-table-td read text-center",
    thClass: "notifications-table-th text-left"
  }
]

export const columns = configPrepare(fields, { i18nPrefix, defaults })
